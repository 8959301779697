<template>
    <div>
        <Header :instance-info="instanceInfo" />
        <div class="container page-wrapper">
            <div class="row">
                <div class="col-12">
                    <h1 class="page-title">Checkout</h1>
                </div>
            </div>

            <form id="buy-subscription-form" class="payment-row" @submit.prevent="submitPayment">
                <div class="review-column">
                    <h3>Order</h3>

                    <div class="product-review">
                        <p>{{ instanceInfo.subscriptionPlan.trialLength }} Day Trial</p>
                        <p>{{ instanceInfo.subscriptionPlan.initialTariff }} {{ instanceInfo.subscriptionPlan.currency }}</p>
                    </div>

                    <div class="product-review totals">
                        <p>
                            <small>Total:</small> <br>
                            {{ instanceInfo.subscriptionPlan.initialTariff }} {{ instanceInfo.subscriptionPlan.currency }}
                        </p>
                        <p>
                            <small>To pay:</small> <br>
                            <span id="currency-display">{{ instanceInfo.subscriptionPlan.initialTariff }} {{ instanceInfo.subscriptionPlan.currency }}</span>
                        </p>
                    </div>

                </div>
                <div class="pay-column">
                    <h3>Payment</h3>
                    <div class="form-group">
                        <label for="card-currency">Currency</label>
                        <select id="card-currency" v-model="currentCurrency" class="form-control" name="currency" data-currency-set="" required=""
                            @change="$emit('update:currency', $event.target.value)">
                                <option v-for="sp in instanceInfo?.subscriptionPlans" :key="sp.currency" :value="sp.currency">{{ sp.currency }}</option>
                                </select>
                    </div>
                    <div class="form-group">
                        <label for="card-number">Card number</label>
                        <div class="input-row">
                          <input id="card-number" v-model="cardNumber" type="text" class="form-control" placeholder="Number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                 name="card[number]" data-accept="digits" maxlength="16" required="" style="flex: 3;">

                          <input id="card-ccv" v-model="cardCode" type="tel" class="form-control" placeholder="CVV" name="card[code]" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                 data-accept="digits" required="" maxlength="3" style="flex: 1;">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="card-year">Expires on</label>
                        <div class="input-row">
                          <select
                              id="card-month"
                              v-model="cardExpiryMonth"
                              required=""
                              class="form-control"
                          >
                            <option value="" hidden>Month</option>
                            <option
                                v-for="n in 12"
                                :key="n.toString().padStart(2, '0')"
                                :value="n.toString().padStart(2, '0')"
                            >
                              {{ n.toString().padStart(2, '0') }}
                            </option>
                          </select>

                          <select
                              id="card-year"
                              v-model="cardExpiryYear"
                              required=""
                              class="form-control"
                          >
                            <option value="" hidden>Year</option>
                            <option
                                v-for="n in 10"
                                :key="new Date().getFullYear() + n - 1"
                                :value="(new Date().getFullYear() + n - 1).toString()"
                            >
                              {{ new Date().getFullYear() + n - 1 }}
                            </option>
                          </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="card-name">Name on card</label>
                        <input  id="card-name" v-model="cardHolder" type="text" name="card[holder]" class="form-control" required=""
                            placeholder="Name">
                    </div>
                </div>
                <div class="footer-column">
                    <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" name="checks[privacy]" required=""> I have read and accept the
                                <router-link to="/privacy">Privacy Policy</router-link> and <router-link to="/terms">Terms
                                    &amp; Conditions</router-link>.
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" name="checks[subscription]" required="">
                                By clicking 'Complete Payment' you agree that you are engaging in a {{ instanceInfo.subscriptionPlan.trialLength }} Days Trial Membership
                                (starting today). Your credit card will be automatically billed every {{ instanceInfo.subscriptionPlan.daysInterval }} days from the end
                                date of your trial period,
                                If you don't cancel your trial subscription within the first {{ instanceInfo.subscriptionPlan.trialLength }} days your membership will be
                                automatically renewed for {{ instanceInfo.subscriptionPlan.recurringTariff }} {{ instanceInfo.subscriptionPlan.currency }} every {{ instanceInfo.subscriptionPlan.daysInterval }} days.
                                To cancel subscription you can login in the <a :href="'https://' + instanceInfo?.domain + '/login'" target="_blank">Portal</a>
                                and then go to <a :href="'https://' + instanceInfo?.domain + '/settings'" target="_blank">Settings</a> and click "Cancel my subscription".
                                Alternatively you can use <a :href="'https://' + instanceInfo?.domain + '/contact'" target="_blank">Contact Form</a>, provide your data
                                (used when subscribing) and submit message to our Customer Service or send email to <a :href="'mailto:'+ instanceInfo?.supportEmail">{{ instanceInfo.supportEmail }}</a>
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" name="checks[charges]" required="">
                                Charges made to your credit card will appear under "{{ instanceInfo.supportDomain }} {{
                                    instanceInfo.phoneNumber }}", operated by <span class="rtl">{{ companyName }}</span>, an eCommerce
                                Merchant located {{ instanceInfo.companyAddress1 }}, {{ instanceInfo.companyAddress2 }}.
                            </label>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-default">Complete Payment</button>
                    <hr>
                    <div class="payment-logos">
                        <img src="/theme/001/images/payment/payment_logos.png" alt="Payment method logo">
                    </div>
                </div>
            </form>


        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs" />
    </div>
<link href="/theme/001/css/checkout.css" rel="stylesheet">
</template>

<script>
import { ref, toRef, watch } from 'vue';
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';
import { makePayment } from '@/app/api/user';
import {createAndSendForm, createIFrameAndResolveMessage, handlePrecondition} from '@/app/use/payment';
import { redirectToRoute } from '@/app/router';
import { useUser } from '@/app/use/user';
import {loadScript} from "vue-plugin-load-script";

export default {
    name: 'Subscription',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
    setup(props) {
    const subscriptionPlan = toRef(props.instanceInfo, 'subscriptionPlan');
    const currentCurrency = ref(subscriptionPlan.value.currency);

    watch(
      () => props.instanceInfo,
      async () => {
        currentCurrency.value = props.instanceInfo.subscriptionPlan.currency;
      },
      { immediate: true, deep: true }
    );

    const { userName } = useUser();

    const cardNumber = ref(null);
    const cardHolder = ref(null);
    const cardCode = ref(null);
    const cardExpiryMonth = ref((new Date().getMonth()+1));
    const cardExpiryYear = ref(new Date().getFullYear());

    const isPaying = ref(false);

    const submitPayment = () => {
        isPaying.value = true;
        const payRequest = {
          currency: currentCurrency.value,
          card: {
            holder: cardHolder.value,
            number: cardNumber.value,
            code: cardCode.value,
            expiry: cardExpiryMonth.value.toString().padStart(2, '0') + cardExpiryYear.value,
          },
          browser: {
            language: navigator.language,
            screenHeight: screen.height,
            screenWidth: screen.width,
            screenColorDepth: screen.colorDepth,
            timezone: new Date().getTimezoneOffset(),
            userAgent: navigator.userAgent,
            javaEnabled: false,
            javaScriptEnabled: true,
          }
        };
        makePayment(payRequest)
          .then(response => {
            handleResponse(response);
          })
          .catch(() => {
            redirectToRoute('/credits/subscription/failure');
          })
          .finally(() => (isPaying.value = false));
    }

    const submitPaymentWith3DSData = (transactionId, threeDS) => {
        isPaying.value = true;
        const payRequest = {
          currency: currentCurrency.value,
          card: {
            holder: cardHolder.value,
            number: cardNumber.value,
            code: cardCode.value,
            expiry: cardExpiryMonth.value.toString().padStart(2, '0') + cardExpiryYear.value,
          },
          browser: {
            language: navigator.language,
            screenHeight: screen.height,
            screenWidth: screen.width,
            screenColorDepth: screen.colorDepth,
            timezone: new Date().getTimezoneOffset(),
            userAgent: navigator.userAgent,
            javaEnabled: false,
            javaScriptEnabled: true,
          },
          threeDS: {
            transactionId: transactionId,
            data: btoa(JSON.stringify(threeDS))
          }
        };
        makePayment(payRequest)
          .then(response => {
            handleResponse(response);
          })
          .catch(() => {
            redirectToRoute('/credits/subscription/failure');
          })
          .finally(() => (isPaying.value = false));
    }

    const handlePendingResponse = (response) => {
      const threeDSResponse = response.threeDSResponse;

      if (response.gateway === 'NET_VALVE') {
        const transactionId = threeDSResponse.transactionId;
        const url = threeDSResponse.url;
        const isHidden = threeDSResponse.hidden;

        createIFrameAndResolveMessage(url, isHidden).then(res => {
          submitPaymentWith3DSData(transactionId, res);
        });

        return;
      }

      if (response.gateway === 'BILL_1ST') {
        const merchantCode = threeDSResponse.merchantCode;
        const jwt = threeDSResponse.jwt;
        const transactionId = threeDSResponse.transactionId;
        const amount = threeDSResponse.amount;
        const currencyNumericCode = threeDSResponse.currencyNumericCode;
        const force = threeDSResponse.force;

        loadScript(
            'https://secure3d.bill1st.com/js/v2/Bill1stSecure3D.js?profile='
        )
            .then(() => {
              // eslint-disable-next-line no-undef
              const Secure = new Secure3D();
              Secure.setup(merchantCode, jwt);
              Secure.enableBinDetection('card-number');
              Secure.on('payments.validated', function (data) {
                submitPaymentWith3DSData(transactionId, data);
              });

              Secure.on('payments.noAction', function (data) {
                submitPaymentWith3DSData(transactionId, data);
              });

              Secure.on('payments.setupComplete', function () {
                try {
                  const order = {
                    Consumer: {
                      Email1: userName.value,
                      BillingAddress: {
                        FullName: cardHolder.value
                      },
                      Account: {
                        AccountNumber: cardNumber.value,
                        ExpirationMonth: cardExpiryMonth.value.toString().padStart(2, '0'),
                        ExpirationYear: cardExpiryYear.value,
                        CardCode: cardCode.value,
                        NameOnAccount: cardHolder.value
                      }
                    },
                    OrderDetails: {
                      Amount: amount,
                      CurrencyCode: currencyNumericCode
                    }
                  };
                  console.log(order);
                  if (force) {
                    Secure.forceAuthentication(order);
                  } else {
                    Secure.do3D(order);
                  }
                } catch (error) {
                  redirectToRoute('/credits/subscription/failure');
                }
              });

              Secure.on('payments.errorSetup', function () {
                redirectToRoute('/credits/subscription/failure');
              });
            });
      } else {
        const threeDUrl = threeDSResponse.url;
        const preconditions = threeDSResponse.preconditions;
        const threeDUrlParams = threeDSResponse.params;
        if (threeDUrl != null && preconditions && preconditions.length) {
          Promise.all(
            preconditions.map((precondition, index) =>
              handlePrecondition({
                index,
                ...precondition
              }),
            ),
          )
            .then(() => {
              createAndSendForm(threeDUrl, threeDUrlParams);
            })
            .catch(() => {
              redirectToRoute('/credits/subscription/failure');
            });
        } else if (threeDUrl != null) {
          createAndSendForm(threeDUrl, threeDUrlParams);
        } else {
          redirectToRoute('/credits/subscription/failure');
        }
      }
    };

    const handleResponse = (response) => {
      console.log("RESPONSE");
      if (response.status === 'SUCCESSFUL') {
        redirectToRoute('/credits/subscription/success');
      } else if (response.status === 'PENDING') {
        handlePendingResponse(response);
      } else {
        redirectToRoute('/credits/subscription/failure');
      }
    };

    return { currentCurrency, cardNumber, cardHolder, cardCode, cardExpiryMonth, cardExpiryYear, submitPayment };
  },
};
</script>
